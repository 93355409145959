/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

 // OL Open Layers
 @import "../node_modules/ol/ol.css";
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~swiper/scss";
// @import "~swiper/scss/autoplay";
// @import "~swiper/scss/keyboard";
// @import "~swiper/scss/pagination";
// @import "~swiper/scss/scrollbar";
// @import "~swiper/scss/zoom";
@import "./assets/styles/myStyles.scss";
.w-100{
    width: 100% !important;
}
.h-100{
    height: 100% !important;
}
.h-25 {
	height: 25% !important;
}
.h-5{
    height: 5vh !important;
}
.text-align{
    text-align: center;
}
.center-div-horizontal {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
ion-modal {
    .modal-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.margin-left-2 {
    margin-left: 2%;
}
.full-size {
    height: 100%;
    width: 100%;
}

.swiper-slide {
    overflow-y: auto;
}
.flex-container {
    display: flex;
    flex-direction: column;
}

