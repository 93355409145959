// San Francisco Thin
@font-face {
    font-family: San Francisco;
    src: url(../assets/fonts/San-Francisco/SF-Pro-Display-Thin.otf) format("opentype");
    font-weight: 100;
}

// San Francisco Light
@font-face {
    font-family: San Francisco;
    src: url(../assets/fonts/San-Francisco/SF-Pro-Display-Light.otf) format("opentype");
    font-weight: 300;
}

// San Francisco Regular
@font-face {
    font-family: San Francisco;
    src: url(../assets/fonts/San-Francisco/SF-Pro-Display-Regular.otf) format("opentype");
    font-weight: 400;
}

// San Francisco Medium
@font-face {
    font-family: San Francisco;
    src: url(../assets/fonts/San-Francisco/SF-Pro-Display-Medium.otf) format("opentype");
    font-weight: 500;
}

// San Francisco Bold
@font-face {
    font-family: San Francisco;
    src: url(../assets/fonts/San-Francisco/SF-Pro-Display-Bold.otf) format("opentype");
    font-weight: 700;
}

// San Francisco Black
@font-face {
    font-family: San Francisco;
    src: url(../assets/fonts/San-Francisco/SF-Pro-Display-Black.otf) format("opentype");
    font-weight: 900;
}