.san-marino {
    /* Settings Fonts Size And Icon Size
        =======================================================
        =======================================================*/
    --fallback-color: red;
    --h1-font-size: 24px;
    --h2-font-size: 22px;
    --h3-font-size: 17px;
    --h4-font-size: 15px;
    --h5-font-size: 12px;
    --h6-font-size: 10px;
    --text-font-size: 14px;

    /* Settings Fonts Size Icon
        =======================================================
        =======================================================*/
    --icon-size-small: 17px;
    --icon-size-medium: 22px;
    --icon-size-large: 32px;
    --icon-size-extra-large: 48px;

    /* Settings Color Background Our App
      =======================================================
      =======================================================*/
    --cs-background-primary: #fff;
    --cs-background-secondary: #EFEFF4;
    --cs-background-accent: #4D79AF;
    --cs-background-dark: #787878;
    --cs-background-border: #C7C7CC;

    /* Settings Color Text Our App
      =======================================================
      =======================================================*/
    --cs-text-primary: #292F36;
    --cs-text-secondary: #8E8E93;
    --cs-text-light: #ffffff;
    --cs-text-accent: #4D79AF;

    /* Settings Size Avatar
      =======================================================
      =======================================================*/
    --size-avatar: 72px;

    /* Settings Size Thumbnail
      =======================================================
      =======================================================*/
    --size-thumbnail: 120px;

    /* Settings Border Radius
      =======================================================
      =======================================================*/
    --border-radius: 8px;

    /* Settings Color Icon
      =======================================================
      =======================================================*/
    --cs-icon-color-primary: #292F36;
    --cs-icon-color-secondary: #C7C7CC;
    --cs-icon-color-accent: #4D79AF;
    --cs-icon-color-light: #fff;

    /* Settings Color Gradient Our App
    =======================================================
    =======================================================*/
    --cs-blandModes-primary: #000000;
    --cs-blandModes-accent: #4D79AF;
  }
