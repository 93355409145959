
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
/** Ionic CSS Variables **/


//////////////////////////////////////////////////
// Theme: Ionic 4 UI Theme/Template App - Material Design - Necco
// Web Site: https://csform.com/
// Author: CSFORM
// Email support:  dev@csform.com
//////////////////////////////////////////////////



:root {
  /* Settings Fonts Size And Icon Size
      =======================================================
      =======================================================*/
  --fallback-color: red;
  --h1-font-size: 24px;
  --h2-font-size: 22px;
  --h3-font-size: 17px;
  --h4-font-size: 15px;
  --h5-font-size: 12px;
  --h6-font-size: 10px;
  --text-font-size: 14px;

  /* Settings Fonts Size Icon
      =======================================================
      =======================================================*/
  --icon-size-small: 17px;
  --icon-size-medium: 22px;
  --icon-size-large: 32px;
  --icon-size-extra-large: 48px;

  /* Settings Color Background Our App
    =======================================================
    =======================================================*/
  --cs-background-primary: #fff;
  --cs-background-secondary: #EFEFF4;
  --cs-background-accent: #5055da; //#21E7B6;
  --cs-background-dark: #787878;
  --cs-background-border: #C7C7CC;

  /* Settings Color Text Our App
    =======================================================
    =======================================================*/
  --cs-text-primary: #292F36;
  --cs-text-secondary: #8E8E93;
  --cs-text-light: #ffffff;
  --cs-text-accent: #070e09;

  /* Settings Size Avatar
    =======================================================
    =======================================================*/
  --size-avatar: 72px;

  /* Settings Size Thumbnail
    =======================================================
    =======================================================*/
  --size-thumbnail: 120px;

  /* Settings Border Radius
    =======================================================
    =======================================================*/
  --border-radius: 8px;

  /* Settings Color Icon
    =======================================================
    =======================================================*/
  --cs-icon-color-primary: #292F36;
  --cs-icon-color-secondary: #C7C7CC;
  --cs-icon-color-accent: #5055da; //#21E7B6;
  --cs-icon-color-light: #fff;

  /* Settings Color Gradient Our App
  =======================================================
  =======================================================*/
  --cs-blandModes-primary: #23278a;
  --cs-blandModes-accent: #5055da; //#21E7B6;
}

/* INCLUDE FONTS AND COLOR COMBINATION
=======================================================
=======================================================*/
@import "fontello";
@import "san-francisco";
@import "rtl";
@import "animation";
// Oder Color Combination
@import "themes-color/turquoise";
@import "themes-color/terracotta";
@import "themes-color/supernova";
@import "themes-color/san-marino";
@import "themes-color/red-orange";
@import "themes-color/pizazz";
@import "themes-color/malibu";
@import "themes-color/java";
@import "themes-color/energy-yellow";
@import "themes-color/emerald";


/* Global Settings Bland Mode And Gradient
================================================*/
//Settings For Background
.darkening-blend-mode {
  position: relative;
  background-color: var(--cs-blandModes-accent) !important;
  background-blend-mode: multiply;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--cs-blandModes-primary);
    mix-blend-mode: lighten;
  }
}
//Settings For Image

.gradient-image {
  position: relative;
  -webkit-filter: none;
  filter: none;
  opacity: unset;
  background-color: var(--cs-blandModes-accent) !important;
  background-blend-mode: multiply;
  z-index: 999;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--cs-blandModes-primary);
    mix-blend-mode: lighten;
    z-index: 1;
  }

  ion-img, img {
    position: relative;
    mix-blend-mode: multiply;
    //-webkit-filter: grayscale(100%) contrast(1.2);
    //filter: grayscale(100%) contrast(1);
  }
}

/* Setting Avatar
=======================================================
=======================================================*/
ion-avatar {
  height: var(--size-avatar);
  width: var(--size-avatar);
}

.ios {
  ion-avatar {
    margin: 8px 0;
  }
  ion-thumbnail {
    margin: 8px 0;
  }
  ion-segment {
    padding: 10px 0 0 0;
    ion-segment-button {
      --indicator-color: transparent;
    }
    .segment-button-checked {
      border-bottom: 2px solid var(--cs-background-accent);
    }
  }
  [slot=start] {
    margin-inline-end: 16px;
  }
}

/* Setting Thumbnail
=======================================================
=======================================================*/
ion-thumbnail {
  --border-radius: 5px;
  height: var(--size-thumbnail);
  width: var(--size-thumbnail);
}

/* Settings Black&White Avatar and Image Section
  =====================================================*/
  ion-avatar,
  ion-thumbnail {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.7;
      overflow: hidden;
  }

  /* Global Settings Image Filetr Section
  ================================================*/
  .images-filter {
      mix-blend-mode: multiply;
      -webkit-filter: grayscale(100%) contrast(1.2);
      filter: grayscale(100%) contrast(1);
  }
/* SETTINGS ION-HEADER
=======================================================
=======================================================*/

.header-md:after {
  background-image: none !important;
}

/* SETTINGS TOOLBAR
=======================================================
=======================================================*/
ion-toolbar {
  --background: var(--cs-background-primary);
  --color: var(--cs-text-accent);
  --border-color: transparent;

  [slot=start] {
    margin-inline-end: 0;
  }

  .ios {
    ion-back-button,
    ion-menu-button {
      --color: var(--cs-text-accent);
    }
  }

  ion-title {
    font-weight: 400 !important;
    font-size: var(--h3-font-size, 17px) !important;
  }
}

ion-item {
  --ripple-color: transparent;
  --background: var(--cs-background-primary);
  --border-color: var(--cs-background-border);
  --background-hover: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --ripple-color: transparent;
}

.margin-half {
  margin: 8px;
}

.padding-half {
  padding: 8px;
}

/* INCLUDE FONTS FOR BODY
=======================================================
=======================================================*/
body {
  --ion-font-family: 'San Francisco', sans-serif;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* SETTINGS FONT SIZE
=======================================================
=======================================================*/

.text-size-xl {
  font-size: var(--h1-font-size, 24px) !important;
  line-height: 29px;
  letter-spacing: 1px;
}

.text-size-lg {
  font-size: var(--h2-font-size, 22px) !important;
  line-height: 27px;
  letter-spacing: 1px;
}

.text-size-md {
  font-size: var(--h3-font-size, 17px) !important;
  line-height: 22px;
  letter-spacing: 1px;
}

.text-size-xs {
  font-size: var(--h4-font-size, 15px) !important;
  line-height: 20px;
  letter-spacing: 1px;
}

.text-size-sm {
  font-size: var(--h5-font-size, 12px) !important;
  line-height: 18px;
  letter-spacing: 1px;
}

.text-size-xxs {
  font-size: var(--h6-font-size, 10px) !important;
  line-height: 18px;
  letter-spacing: 1px;
}

.text-size-p {
  font-size: var(--text-font-size, 14px) !important;
  line-height: 20px;
  letter-spacing: 1px;
}

.text-nowrap {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* SETTINGS FONT WEIGHT
=======================================================
=======================================================*/

.font-light {
  font-weight: 300 !important;
}

.font-regular {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-bold {
  font-weight: 700 !important;
}

/* SETTINGS COLOR
=======================================================
=======================================================*/

.text-color-primary {
  color: var(--cs-text-primary) !important;
}

.text-color-secondary {
  color: var(--cs-text-secondary) !important;
}

.text-color-accent {
  color: var(--cs-text-accent) !important;
}

.text-color-light {
  color: var(--cs-text-light) !important;
}

.error-color {
  color: red !important;
}


/* SETTINGS ICON COLOR
=======================================================
=======================================================*/

.icon-color-primary {
  color: var(--cs-icon-color-primary) !important;
}

.icon-color-secondary {
  color: var(--cs-text-secondary) !important;
}

.icon-color-accent {
  color: var(--cs-icon-color-accent) !important;
}

.icon-color-light {
  color: var(--cs-icon-color-light) !important;
}

/* SETTINGS ICON FONT
=======================================================
=======================================================*/

.icon-font-sm {
  font-size: var(--icon-size-small, 17px) !important;
}

.icon-font-md {
  font-size: var(--icon-size-medium, 22px) !important;
}

.icon-font-lg {
  font-size: var(--icon-size-large, 32px) !important;
}

.icon-font-xl {
  font-size: var(--icon-size-extra-large, 48px) !important;
}

/* SETTINGS COLOR BACKGROUND OUR APP
=======================================================
=======================================================*/
ion-page {
  background: var(--cs-background-primary);
}

ion-content {
  --background: var(--cs-background-primary);
}

ion-list {
  padding-top: 0 !important;
  background: var(--cs-background-primary) !important;
}

/* SETTINGS ITEM SLIDING
=======================================================
=======================================================*/

ion-item-option {
  --background: var(--cs-background-primary);
  width: auto;
}

/* SETTINGS ITEM
=======================================================
=======================================================*/

/* SETTINGS MAIN MENU
=======================================================
=======================================================*/

.ion-menu-toggle,
ion-menu-toggle {
  .icon-small {
    margin-right: 10px;
  }

  ion-label {
    overflow: hidden !important;

    h1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

/* SETTINGS BUTTON SIZE
=======================================================
=======================================================*/

.button-default {
  --color: var(--cs-text-light) ;
  --background: var(--cs-background-accent) !important;
  --padding-top: 12px;
  --padding-bottom: 12px;
  --padding-start: 20px;
  --padding-end: 20px;
  --border-radius: 8px;
  --box-shadow: none;
  font-size: var(--h3-font-size, 17px);
  font-weight: 300;
  height: auto;
  --background-hover: var(--cs-background-accent) !important;
  --color-hover:  var(--cs-text-light) !important;
  --color-activated: var(--cs-text-light) !important;
  --color-focused: var(--cs-text-light) !important;
  --ripple-color: transparent !important;
}

.button-round {
  --background: transparent !important;
  --background-hover: transparent !important;
  --border-width: 1px;
  --color: var(--cs-text-accent) !important;
  --color-hover: var(--cs-text-accent) !important;
  --color-activated: var(--cs-text-accent) !important;
  --color-focused: var(--cs-text-accent) !important;
  --ripple-color: transparent !important;
  --padding-top: 15px;
  --padding-bottom: 15px;
  --padding-start: 18px;
  --padding-end: 18px;
  --border-radius: 24px;
  --border-color: var(--cs-background-accent) !important;
  font-size: var(--h3-font-size, 17px);
  font-weight: 300;
}

.button-icon {
  --background: transparent !important;
  --color: var(--cs-text-primary) !important;
  --box-shadow: none;
  font-size: var(--h3-font-size, 17px) !important;
  font-weight: 300 !important;
  --background-hover: transparent !important;
  --color-hover: var(--cs-text-primary);
  --color-activated: var(--cs-text-primary) !important;
  --color-focused: var(--cs-text-primary) !important;
  --ripple-color: transparent !important;

  [slot=start] {
    margin-inline-end: 8px !important;
  }
}

.button-clear {
  --background: transparent !important;
  --color: var(--cs-text-accent)!important;
  --box-shadow: none;
  font-size: var(--h3-font-size, 17px) !important;
  font-weight: 300 !important;
  height: auto;
  --background-hover: transparent !important;
  --color-hover: var(--cs-text-accent)!important;
  --color-activated: var(--cs-text-accent)!important;
  --color-focused: var(--cs-text-accent)!important;
  --ripple-color: transparent !important;
}

.button-action-shit {
  --border-radius: 50% !important;
  min-height: 40px !important;
  min-width: 40px;
  --box-shadow: none !important;
  --background: var(--cs-background-accent) !important;
  --background-hover: var(--cs-background-accent) !important;
  --ripple-color: transparent !important;
}

/* SETTINGS CHECK BOX
=======================================================
=======================================================*/

ion-checkbox {
  --background-checked: var(--cs-background-accent);
  --border-color-checked: var(--cs-background-accent);
  --background: transparent;
  --border-color: var(--cs-background-accent);
  --border-radius: 4px;
  --checkmark-color: var(--cs-text-light);
}

/* SETTINGS TOOGLE
=======================================================
=======================================================*/

ion-toggle {
  --background: var(--cs-background-border);
  --background-checked: var(--cs-background-accent);
  --handle-background: var(--cs-background-primary);
  --handle-background-checked: var(--cs-background-primary);
  padding-inline-start: 4px;
  padding-inline-end: 4px;
}

/* SETTINGS RADIO
=======================================================
=======================================================*/

ion-radio {
  --color: var(--cs-background-dark);
  --color-checked: var(--cs-background-accent);
}
/* SETTINGS TABS
=======================================================
=======================================================*/

ion-tab-bar {
  --background: var(--cs-background-accent);
}

ion-tab-button {
  font-size: var(--h4-font-size, 15px);
  --color: var(--cs-text-light);
  --color-selected: var(--cs-text-primary);
  --ripple-color: transparent !important;
}
/* SETTINGS RANGE
=======================================================
=======================================================*/

ion-range {
  --knob-background: var(--cs-background-accent);
  --bar-background-active: var(--cs-background-accent);
  --bar-background: var(--cs-background-border);
  --pin-background: var(--cs-background-accent);
}

/* SETTINGS ION-BADGE
=======================================================
=======================================================*/

ion-badge {
  --background: var(--cs-background-accent);
  --color: var(--cs-text-light);
  --padding-bottom: 5px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 5px;
  border-radius: 24px;
  font-weight: bold;
  font-size: var(--h4-font-size, 15px);
}

/* SETTINGS SEARCHBAR
=======================================================
=======================================================*/

ion-searchbar {
  --background: var(--cs-background-secondary) !important;
  --cancel-button-color: var(--cs-text-primary) !important;
  --clear-button-color: var(--cs-text-primary) !important;
  --color: var(--cs-text-primary) !important;
  --icon-color: var(--cs-text-primary) !important;
  --placeholder-color: var(--cs-text-primary) !important;

  .searchbar-input {
    border-radius: 10px !important;
    box-shadow: none !important;
  }
}
/* SETTINGS SEGMENTS
=======================================================
=======================================================*/
ion-segment {
  background: transparent;
}

ion-segment-button {
  --background: transparent;
  --background-checked: transparent !important;
  --background-focused: transparent !important;
  --background-hover: transparent !important;
  --ripple-color: transparent !important;
  --color: var(--cs-text-primary);
  --color-checked: var(--cs-text-accent) !important;
  --color-focused: var(--cs-text-primary) !important;
  --color-hover: var(--cs-text-primary) !important;
  --indicator-box-shadow: none;
  --border-width: 0 !important;
  --border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  margin: 5px;
  min-width: unset;
  max-width: unset;
}

/* SETTINGS SUPER TAB
=======================================================
=======================================================*/
super-tabs-toolbar {
  &:after {
    background-image: none !important;
  }
}

super-tabs-container, super-tab {
  width: 100%;
}

super-tab-button {
  flex-grow: 1;
  max-width: unset;
}

/* SETTINGS NEW-CATEGORY
=======================================================
=======================================================*/

.new-category {
  background-color: var(--cs-background-accent);
  color: var(--cs-text-light);
  font-size: var(--h4-font-size, 15px);
  align-self: center;
  border-radius: 24px;
  font-weight: bold;
  line-height: 17px;
  padding: 5px 10px;
}

/* SETTINGS WIZARD
=======================================================
=======================================================*/

.swiper-pagination-bullet-active {
  --bullet-background: var(--cs-background-accent) !important;
  background: var(--bullet-background) !important;
}

cs-wizard-layout-1 {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: 50px !important;
  }
}

cs-wizard-layout-2 {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: 0 !important;
  }
}

cs-wizard-layout-3 {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: 50px !important;
  }
}

cs-wizard-layout-4 {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: 10px !important;
  }
}

cs-profile-layout-2 {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: -35px !important;
  }
}

cs-profile-layout-4 {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: -25px !important;
  }
}

/* Default Overview Ionic Section
=======================================================
=======================================================*/

.background-size {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.background-primary {
  background-color: var(--cs-background-primary);
  --background-color: var(--cs-background-primary);
}

.background-accent {
  background-color: var(--cs-background-accent);
}

.background-red {
  background-color: red;
}

[slot=end] {
  margin-inline-start: 0;
}

[slot=start] {
  margin-inline-end: 16px;
}

.border {
  border-bottom: 1px solid var(--cs-background-border) !important;
}

.border-radius {
  border-radius: 8px !important;
  --border-radius: 8px !important;
  overflow: hidden
}

.border-radius-left {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.no-box-shadow {
  box-shadow: none !important;
}

.transparent {
  --background: transparent;
}

.box-shadow {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2) !important;
  position: relative;
  z-index: 999;
}

.clearfix {
  display: block;
  clear: both;
}

/* Settings Spinner
================================================
================================================*/
ion-spinner {
  width: 40px;
  height: 40px;
  color: var(--cs-background-accent) !important;
}

cs-spinner {
  &.svg-size {
    font-size: 45px;
    color: var(--cs-background-accent) !important;
    stroke: var(--cs-background-accent) !important;
    fill: var(--cs-background-accent) !important;
  }
}
/* Settings Reorder Group
================================================
================================================*/
ion-reorder-group {
  ion-item {
    --inner-padding-end: 0 !important;
  }

  ion-reorder {
    display: flex !important;
    align-items: center !important;
    height: 100% !important;
    background: transparent !important;
    border-left: 1px solid var(--cs-background-border);
  }
}
/* Settings Picker
================================================
================================================*/
ion-picker {
  .picker-button {
    color: var(--cs-text-accent) !important;
  }

  .picker-opt {
    color: var(--cs-text-primary) !important;
  }

  .picker-opt-selected {
    color: var(--cs-text-accent) !important;
  }
}

/* Settings Select
================================================
================================================*/

ion-select {
  color: var(--cs-text-primary) !important;
  font-weight: 400 !important;
  font-size: var(--h3-font-size, 17px) !important;
}

/* Settings Alert
================================================
================================================*/

ion-alert {
  .alert-wrapper {
    background: var(--cs-background-secondary) !important;
    .alert-head {
      .alert-title {
        font-size: var(--h5-font-size, 15px) !important;
        font-weight: 300 !important;
        color: var(--cs-text-primary) !important;
      }

      .alert-sub-title {
        font-size: var(--h5-font-size, 15px) !important;
        font-weight: 300 !important;
        color: var(--cs-text-accent) !important;
      }
    }

    .alert-message {
      color: var(--cs-text-primary) !important;
      font-size: var(--h4-font-size);
    }

    .alert-radio-group {
      border-top: 1px solid var(--cs-background-dark);
      border-bottom: none;

      .alert-radio-label {
        font-size: var(--h4-font-size) !important;
        font-weight: 300 !important;
        color: var(--cs-text-primary) !important;
      }

      .alert-radio-inner {
        border-color: var(--cs-background-accent) !important;
      }

      .alert-radio-icon {
        border-color: var(--cs-background-dark) !important;
      }

      .alert-radio-inner.sc-ion-alert-md {
        background-color: var(--cs-background-accent) !important;
      }

      [aria-checked=true] .alert-radio-icon {
        border-color: var(--cs-background-accent) !important;
      }

      [aria-checked=true] .alert-radio-label {
        color: var(--cs-text-accent) !important;
      }
    }

    .alert-checkbox-group {
      border-top: 1px solid var(--cs-background-dark);
      border-bottom: none;

      .alert-checkbox-label {
        font-size: var(--h4-font-size) !important;
        font-weight: 300 !important;
        color: var(--cs-text-primary) !important;
      }

      .alert-checkbox-icon {
        border-color: var(--cs-background-dark) !important;
      }

      [aria-checked=true] .alert-checkbox-label {
        color: var(--cs-text-accent) !important;
      }

      [aria-checked=true] .alert-checkbox-icon {
        background: var(--cs-background-accent) !important;
        border-color: var(--cs-background-accent) !important;
      }
    }

    .alert-button-group {
      background: var(--cs-background-accent) !important;
      .alert-button {
        font-size: var(--h5-font-size, 15px) !important;
        font-weight: 400 !important;
        color: var(--cs-text-light) !important;
        border: none;
        display: flex;
      }
    }
  }
}

/* Settings Popover
================================================
================================================*/

ion-select-popover {
  ion-list {
    padding: 0 !important;

    ion-item {
      --background: var(--cs-background-primary) !important;
    }

    ion-label {
      font-size: var(--h4-font-size, 15px) !important;
      font-weight: 300 !important;
      color: var(--cs-text-primary) !important;
    }
  }
}

ion-action-sheet {
  .action-sheet-group {
    background: var(--cs-background-primary) !important;
  }
  .action-sheet-button {
    background: var(--cs-background-primary) !important;
    color: var(--cs-text-accent) !important;
    font-size: var(--h4-font-size, 15px) !important;
    font-weight: 400 !important;
  }

  .action-sheet-selected {
    color: var(--cs-text-accent) !important;
    font-weight: bold !important;
  }
}

/* Settings Alert Info
================================================
================================================*/
.info-dialog {
  text-align: center;

  .alert-head {
    text-align: center;
  }
}

/* Settings Alert Warning
================================================
================================================*/
.alert-warning {
  text-align: center;

  .alert-wrapper {
    background: var(--cs-background-primary) !important;
}
  .alert-head {
    text-align: center;
  }

  .alert-button-group {
    padding: 8px 0 8px 8px !important;
  }


  button {
    width: 100%;
    margin-bottom: 20px;
    font-size: var(--h4-font-size, 15px);
    background: var(--cs-background-accent) !important;

    .alert-button-inner {
      text-align: center;
      color: var(--cs-text-light) !important;
      display: block;
    }
  }
}

/* Settings Alert Subscribe
================================================
================================================*/
.alert-subscribe {
  .alert-wrapper {
    background: var(--cs-background-primary) !important;
  }
  .alert-input:focus {
    border-bottom: 1px solid var(--cs-background-accent);
  }
  .alert-button-inner {
    color: var(--cs-text-light);
  }
}

/* Settings Alert Dialog
================================================
================================================*/
.basic-dialog {
  .alert-wrapper {
    background: var(--cs-background-primary) !important;
  }
  .alert-input:focus {
    border-bottom: 1px solid var(--cs-background-accent);
  }
}

html, body { height: 100%; }
